.Ceremonies {
    margin-top: $default-space-m;
    margin-inline: $default-space-m;
    width: 100%;
    max-width: 1560px;

    &__firstIcon {
        margin-right: $default-space-m;
        align-self: flex-end;
        margin-bottom: 44px;
        min-width: 24px;
    }

    &__spaceRow {
        margin-bottom: $default-space-xl;
        position: relative;

        &Arrows {
            position: absolute;
            left: 33px;
            bottom: 43px;
            z-index: 10;

            svg {
                margin-right: 12px;
            }
        }
    }

    &__firstRow {
        margin-top: $default-space-s;
    }

    &__managedRow {
        margin-left: 49px;
        width: calc(100% - 49px);
    }
}
.InlineCeremoniesRow {
    z-index: 8;
}

.InlineCeremoniesCard {
    overflow: visible;
    z-index: 8;

    &:hover {
        z-index: 2;
    }
}

.CeremoniesArrow {
    position: absolute;
    z-index: 54;
    pointer-events: none;

    &--relative {
        position: relative;
    }

    &__Line {
    }

    &__Arrow {
        margin-left: -9px;
    }
}

.CeremoniesCard {
    background-color: $white;
    border: 1px solid $blue-lighter;
    border-radius: $border-radius;
    height: 160px;
    position: relative;
    overflow: visible;

    transition: all 300ms ease-in-out;

    padding: $default-space-s;

    &__head {
        font-size: 20px;
        display: block;
        margin-bottom: $default-space-xs;
    }

    &__head2 {
        font-size: 20px;
        color: $blue-light;
        display: block;
    }

    &__iconSection {
        margin-top: $default-space-m;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }

    &:hover {
        cursor: pointer;
        box-shadow: $default-shadow-selected;
    }

    &::after {
        content: '-';
        height: 8px;
        background-color: $gray-light;
        border-radius: 4px;
        display: block;
        color: transparent;
        position: absolute;
        bottom: 8px;
        right: 16px;
        left: 16px;
    }
}

.ReadyRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
}
