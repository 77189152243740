.PageSettings {
    position: absolute;
    top: 28px;
    right: $default-space-l;

    display: flex;
    flex-direction: row;
    align-items: center;

    &--userButton {
        height: 40px !important;
        width: 46px;
        box-shadow: $default-shadow-s;
    }

    &--selector {
        margin-right: $default-space-s;
    }
}
