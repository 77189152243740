.ProAttrField {
    $this: &;
    margin-bottom: $default-space-s;
    position: relative;
    padding: $default-space-xs;

    &__value {
        &--entityLengh {
            &-none .bp3-input {
                padding-right: 8px !important;
            }

            &-s .bp3-input {
                padding-right: 28px !important;
            }

            &-l .bp3-input {
                padding-right: 52px !important;
            }

            &-xl .bp3-input {
                padding-right: 68px !important;
            }
        }
    }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    &__content {
        display: flex;
        flex-direction: row;
    }

    &--fullWith {
        width: 100%;
    }

    &__name {
        display: flex;
        flex-direction: column;
        font-size: $font-size;
        color: rgba($gray-dark, 0.6);

        margin-bottom: $default-space;

        h3 {
            margin-bottom: $default-space-s;
        }
    }

    &__entity {
        position: absolute;
        right: 8px;
        top: 7px;
        font-family: $font-family-sans-serif;
        overflow: hidden;
        color: white;

        &--disabled {
            color: $gray-dark;
        }
    }

    &__input {
        position: relative;
        margin-bottom: $default-space;

        input {
            height: 34px;
        }
    }

    &__has-info {
        input {
            padding-right: $default-space-l !important;
            text-align: right;
        }
    }

    &__allignRight {
        input {
            text-align: right;
            padding-right: 40px !important;
        }
    }

    &__info {
        // margin-right: $default-space;
        // float: right;
        position: absolute;
        left: $default-space-s;
        bottom: 9px;
    }

    &__currency {
        input {
            font-size: 16px;
            text-align: right;
            // padding-right: $default-space-l !important;
            padding-right: 28px !important;
        }
    }

    &__header > input {
        font-size: 22px !important;
        height: 40px;
        color: $white;
        padding: 12px !important;

        background-color: $gray-lighter !important;
    }

    &__dependencies {
        width: 100%;

        &--header {
            margin-bottom: $default-space-s;
            padding-left: $default-space-s;
        }

        &--box {
            background-color: $transparend;
            margin-bottom: $default-space-s;
            border-radius: $border-radius;
            padding: $default-space-s;
            // padding-bottom: 0;
            box-shadow: $default-shadow;
            align-items: center;

            .ProAttrField,
            .ProAttrField__input,
            .ProButton {
                margin-bottom: 0 !important;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &__empty {
                font-size: 14px;
                justify-content: center;
                background-color: $blue-lightest;
                padding: $default-space $default-space-s;
                border-radius: $border-radius-s;
                box-shadow: none;
                justify-content: center !important;
            }
        }

        &__buttons {
            // padding-top: 26px;
            justify-content: flex-end;
            display: flex;
            flex-direction: row !important;
            align-items: center;
            // margin-bottom: $default-space-s;

            .ProButton-Tooltip {
                display: flex;
                align-items: center;
            }
        }
    }

    input,
    textarea,
    &__dropdownSelected {
        box-shadow: none;
        padding: $default-space;
        background-color: $blue-lighter;
        color: $white;
        border-radius: $border-radius-s;
        font-size: 14px;
    }

    #{$this}__icon {
        position: absolute;
        right: $default-space-s;
    }

    ::placeholder {
        color: rgba($color: #fff, $alpha: 0.4);
    }

    .bp3-input:disabled,
    .bp3-input.bp3-disabled {
        // background-color: $blue-lightest;
        color: $text-color-disabled;
        cursor: auto;

        background-color: rgba($blue-lightest, 0.12);
        border: 1px solid rgba($blue-lightest, 0.3);
    }
    &--disabled {
        input,
        textarea,
        #{$this}__dropdownSelected {
            background-color: $blue-lightest;
            color: $text-color-disabled;
            cursor: auto;
        }
        #{$this}__entity {
            color: $text-color-disabled;
            font-size: 14px;
        }
    }

    .bp3-button:not([class*='bp3-intent-']),
    .bp3-button:not([class*='bp3-intent-']):hover {
        @extend .ProAttrField;
        margin-bottom: $default-space;
        background-color: $gray;
    }

    .bp3-popover-wrapper select,
    .bp3-popover-target,
    .bp3-input-group {
        width: 100%;
    }
}
