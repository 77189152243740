.ProValue {
    width: 100%;

    &--value {
        font-family: 'Ubuntu Mono', monospace;
        font-size: 16px;
        text-align: right;
        color: $gray-darker;
    }

    //mixin for intents
    @each $color, $color-val in $pm-colors {
        &__intent-#{$color} {
            .ProAttrField__entity {
                color: white !important;
            }
            .bp3-input-group > input {
                color: white !important;
                background-color: $color-val !important;
            }
        }
    }
}

.ProTableDate {
    margin-left: $default-space;

    .ProValue--value {
        text-align: left;
    }
}
