@mixin indicator($space) {
    position: absolute;
    content: '%';
    color: transparent;
    border-right: 1px solid $gray-darker;
    top: 20px;
    left: $space;
    height: 12px;
    width: 0px;
}
.TresholdSettings {
    width: 100%;
    margin-bottom: $default-space-s;

    .Bar {
        margin-bottom: $default-space-s;
        height: $default-space-s;
        color: transparent;
        display: flex;
        width: 100%;

        &--extreme {
            height: 100%;
            background-color: $red;
            border-radius: $border-radius-s;
            width: 100%;
            display: flex;
            padding: 0 16.6%;
            position: relative;

            &::before {
                @include indicator(14px);
            }

            &::after {
                @include indicator(98%);
            }
        }

        &--noIndicator {
            &::before {
                display: none;
            }

            &::after {
                display: none;
            }
        }

        &--critical {
            background-color: $yellow;
            height: 100%;
            border-radius: $border-radius-s;
            display: flex;
            width: 76%;
            padding: 0 16%;
            position: relative;

            &::before {
                @include indicator(0px);
            }

            &::after {
                @include indicator(100%);
            }
        }

        &--caution {
            background-color: $green;
            height: 100%;
            border-radius: $border-radius-s;
            display: flex;
            width: 60%;
            position: relative;

            &::before {
                @include indicator(0px);
            }

            &::after {
                @include indicator(100%);
            }
        }
    }
}

.TresholdDescription {
    text-align: center;
    margin-right: $default-space-m;
}
