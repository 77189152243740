.ProMenuIcon {
    height: 60px;
    width: 60px;
    border-radius: $default-space;
    margin: 0;
    margin-bottom: $default-space;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &--active {
        &-hightlight {
            background-color: $white;
        }
    }

    &--disabled {
        opacity: 0.6;
        pointer-events: none;
        cursor: not-allowed;
    }
}

.ProMenuItem {
    cursor: pointer;

    &__content {
        display: flex;
        flex-direction: column;
    }
}

.ProMenuButton {
    svg {
        transition: all 300ms ease-in-out;
    }

    &__rotation-0 {
        svg {
            transform: rotate(0deg);
        }
    }

    &__rotation-90 {
        svg {
            transform: rotate(90deg);
        }
    }

    &__rotation-270 {
        svg {
            transform: rotate(270deg);
        }
    }

    &__rotation-180 {
        svg {
            transform: rotate(180deg);
        }
    }
}
