$pro-cell-coll-space: 44px;
$pro-table-max-with: 1600px;
$pro-table-min-with: 900px;
$pro-table-col-gap: $default-space-m;

.ProTable__filterButton {
    margin-left: auto !important;
    margin-right: $default-space-m;
}

.ProTable {
    $this: &;
    border-spacing: $default-space;
    border-collapse: collapse;
    transition: all 500ms ease-in-out;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--wrapper {
        display: flex;
        justify-content: space-between;
        flex: 1 1 $pro-table-min-with;

        min-width: $pro-table-min-with;

        &__shrinked {
            max-width: $pro-table-max-with;
        }

        &__multiTable {
            max-width: unset;
            flex: unset;
        }
    }

    &--outside-button {
        height: fit-content;
    }

    &--content {
        width: 100%;
        overflow: scroll;

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: 10px; /* Firefox */

        &::-webkit-scrollbar {
            // display: none;
            width: 10px;
        }
    }

    @for $i from 1 through 24 {
        &--colspace-#{$i} {
            width: calc(#{$pro-cell-coll-space} * #{$i});
        }
    }

    &--colspace-extend {
        flex-grow: 1;
    }

    &--info {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: $default-space-l;
    }

    &--head {
        // padding-top: $default-space-s;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: $pro-table-col-gap;
        padding-right: 0;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        margin-right: $default-space-m;

        background: $white;
        background: linear-gradient(180deg, $white 0%, rgba(249, 249, 250, 0) 100%);
        backdrop-filter: blur(8px);
        z-index: 10;

        &-cell {
            padding-right: $pro-table-col-gap;
            overflow: hidden;

            margin-top: auto;

            &-right {
                text-align: right;
            }
        }

        &__hidden {
            visibility: hidden;
            width: 0;
            padding: 0;
        }
    }

    &--body {
        overflow-y: auto;
        overflow-x: visible;
        padding-top: $default-space-m;
        padding-left: $default-space-s;
        padding-bottom: $default-space-s;
    }

    &--row {
        position: relative;
        background: none;
        border-radius: $border-radius-s;
        margin-bottom: $default-space;
        margin-right: $default-space-s;
        box-shadow: $default-shadow;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        height: 44px;

        &__levelIntent {
            &-yellow {
                .ProTable__levelBar {
                    background-color: $blue-lighter !important;
                }
            }
        }

        transition: all 100ms ease-in-out;

        #{$this}--actionCell {
            justify-content: flex-end;
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        &:hover,
        &--selected {
            #{$this}--actionCell {
                opacity: 1;
            }
        }

        #{$this}__name {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
            .ProIcon {
                margin-right: $default-space;
            }
        }

        .ProButton {
            height: $default-space-m;
            &--icon-button,
            &--icon-button:hover {
                width: $default-space-m;
                margin: 0;
            }
        }

        &:hover {
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
        }

        &--selected,
        &--selected:hover {
            border: none;
            box-shadow: $default-shadow-selected;
        }

        $pro-table-span: 26px;
        --pro-table-span: 26px;

        @media screen and (min-width: 2280px) {
            --pro-table-span: 24px;
        }

        @media screen and (min-width: 2980px) {
            --pro-table-span: 32px;
        }

        @for $i from 1 through 12 {
            &__level-#{$i} {
                margin-left: calc(#{$i} * #{var(--pro-table-span)});

                @for $j from 1 through 24 {
                    #{$this}--colspace-#{$j}#{$this}--cell:first-of-type {
                        width: calc(#{$pro-cell-coll-space} * #{$j} - (#{$i} * #{var(--pro-table-span)}));
                    }
                }
            }
        }

        #{$this}__level--empty {
            width: 14px;
        }

        #{$this}--cell {
            padding-right: $pro-table-col-gap;
            display: flex;
            align-items: center;
            border-radius: 0;
            line-height: $default-space-l;
            overflow: hidden;
            font-size: 13px;

            transition: all 0.3s ease-in-out;

            // &:last-of-type {
            //     margin-right: $default-space-s;
            // }

            &#{$this}__levels {
                padding: 0;
                line-height: 45px;
                height: 100%;
                display: flex;

                #{$this}__level {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: $default-space;
                    cursor: pointer;

                    svg {
                        transition: all 200ms ease-in-out;
                        transform: rotate(-90deg);
                    }
                }

                #{$this}__level--open {
                    svg {
                        transform: rotate(0deg);
                    }
                }

                #{$this}__levelBars {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    // margin-right: $default-space;
                    margin-right: 12px;

                    #{$this}__levelBar {
                        width: $default-space;
                        height: 34px; // 100%;
                        background-color: rgba(49, 69, 92, 0.05);
                        color: transparent;
                        border-radius: $border-radius-s;
                        margin: 4px 0 4px $default-space;

                        &-1 {
                            background-color: rgba(49, 69, 92, 0.55);
                        }
                        &-2 {
                            background-color: rgba(49, 69, 92, 0.4);
                        }
                        &-3 {
                            background-color: rgba(49, 69, 92, 0.25);
                        }
                        &-4 {
                            background-color: rgba(49, 69, 92, 0.15);
                        }
                        &-5 {
                            background-color: rgba(49, 69, 92, 0.1);
                        }
                    }
                }
            }
        }

        #{$this}__hidden {
            max-width: 0;
            overflow: hidden;
            // visibility: hidden;
            padding: 0;
        }

        #{$this}__buttons {
            outline: none;

            .ProButton-Tooltip {
                margin: 0;
            }
        }

        #{$this}__status {
            width: auto;
        }
    }
    &__statusIcon {
        font-size: 12px !important;
        span {
            margin-right: $default-space;
        }
    }

    #{$this}__name {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        svg {
            margin-right: $default-space;
            margin-bottom: 0;
        }
    }

    &--actionCell--hidden {
        opacity: 0.3;
        transition: all 200ms ease-in-out;
    }

    &--noContentMsg {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        font-weight: 700;
        color: $error;
    }
}
