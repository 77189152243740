.FileList {
    &__name {
        font-size: $font-size;
        margin-bottom: $default-space;
    }

    &__content {
        background-color: $blue-lightest;
        border-radius: 4px;
        padding: $default-space-s;
        font-size: $font-size;

        display: flex;
        margin-right: $default-space;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__upload {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $default-space-s $default-space-l;
        border: 1px dashed $brand-primary;
        margin-left: auto;
        border-radius: $default-space;
        opacity: 0.5;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        label {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0;
            cursor: pointer;
        }

        input {
            // display: none;
            opacity: 0;
            height: 100%;
            top: -20px;
            left: 0;
            position: absolute;
            cursor: pointer;
        }

        svg {
            margin-bottom: $default-space;
            cursor: pointer;
        }
    }
}
