.SummaryWindow {
    width: 100%;
    padding: $default-space-m 0 0 20%;
    display: flex;
    flex-direction: column;
    margin-top: $default-space-xl;

    max-width: $pro-detail-window-max-with;
    min-width: $pro-detail-window-min-with;

    &__content {
        display: flex;
        flex-wrap: wrap;
        // margin-top: $default-space-m;
    }

    @media screen and (max-width: $tableOverflowBreakponint) {
        display: none;
    }
}

.SummaryAttrField {
    display: flex;
    margin-bottom: $default-space-xl;
    margin-right: $default-space-xxl;
    align-items: center;

    svg {
        opacity: 0.6;
    }

    &__content {
        display: flex;
        margin-left: $default-space-m;
        flex-direction: column;
    }

    &__name {
        font-size: 16px;
        margin-bottom: $default-space;
    }
    &__val {
        font-size: 24px;
        font-weight: 700;
    }
}
