$ns: 'bp3';

.ProButton-Tooltip {
    &:last-of-type {
        margin: 0;
    }
}

$button-height: 36px;
$button-height-small: 30px;

.ProButton {
    $this: &;
    font-size: 14px;
    margin-left: $default-space;
    background-color: $light-blue;
    border-radius: $border-radius-s;
    padding: $default-space $default-space-s;
    outline: none;
    box-shadow: none;
    background-image: none;
    opacity: 1;

    transition: all 300ms ease-in-out;

    &__disabled {
        opacity: 0.3 !important;
    }

    &__hidden {
        opacity: 0 !important;
    }

    .bp3-button-text {
        align-items: center;
    }

    span {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
    }

    svg {
        margin-bottom: 2px;
    }

    &:active {
        outline: none;
    }

    &:disabled {
        color: $gray;
    }

    &--textButton {
        white-space: nowrap;

        #{$this}__icon--left {
            margin-right: $default-space;
        }

        #{$this}__icon--right {
            margin-left: $default-space;
        }
    }

    &--buttonGroup {
        margin-right: $default-space;
        flex-wrap: nowrap;
        display: flex;
    }

    &__image {
        height: $default-space-l;
        width: $default-space-l;
    }

    &--group {
        margin: $default-space 0;
        display: flex;
        flex-direction: row;
    }

    &--icon-button {
        padding: $default-space !important;
        // width: $button-height;
        width: 24px;
        height: $button-height;

        &__small {
            width: $button-height-small;
            height: $button-height-small;
        }
    }

    &--table-button {
        padding: $default-space !important;
        height: $default-space-m;
        width: $default-space-m;
    }

    &--main-blue-lighter {
        color: $brand-primary !important;

        svg {
            fill: $brand-primary !important;
        }
    }

    @each $color, $color-val in $pm-colors {
        &--main-#{$color} {
            background-color: $color-val !important;
            color: $white !important;
            box-shadow: none;

            svg {
                fill: $white;
            }
        }
    }

    @each $color, $color-val in $pm-colors {
        &--hover-#{$color}:hover {
            background-color: $color-val !important;
            color: $white !important;
            box-shadow: none;

            svg {
                fill: $white;
            }
        }
    }

    &--hover-transparend:hover {
        background-color: $blue-lightest !important;
    }

    @each $color, $color-val in $pm-colors {
        &--line-#{$color} {
            color: $color-val !important;

            svg {
                fill: $color-val;
            }
        }
    }

    &--line-transparend {
        color: $white !important;
        border: 1px solid $white;

        svg {
            fill: $white;
        }
    }

    &--size-xs {
        padding: 4px 8px !important;
        font-size: 13px !important;
        height: 24px;
        // width: 24px;
    }

    // &--size-xl {
    //     padding: 12px 24px 12px 16px !important;
    //     font-size: 18px !important;

    //     svg {
    //         height: 32px;
    //         width: 32px;
    //     }
    // }
}

//Specifity overwite
.#{$ns}-button:not([class*='bp3-intent-']),
.#{$ns}-button:not([class*='bp3-intent-']):hover {
    @extend .ProButton;
}

.bp3-datepicker-navbar {
    .bp3-button.bp3-minimal {
        background-color: white;
        margin-right: auto;
    }
}

.ProPopover {
    padding: $default-space-m;
    background: $gray-dark;
    border-radius: $border-radius;
    width: 290px;

    h3 {
        color: $white;
    }

    .ProButton {
        margin-bottom: $default-space;

        &__deleteMessage {
            color: $yellow;
        }
    }

    .bp3-popover2-content {
        background: transparent;
    }
}

.bp3-overlay-content {
    outline: none;
}

.bp3-popover2 .bp3-popover2-arrow::before {
    box-shadow: none;
}

.bp3-popover2-arrow {
    height: 30px;
    width: 30px;

    svg,
    .bp3-popover2-arrow-fill,
    .bp3-popover2-arrow-border {
        fill: $gray-dark;
    }
}
