.ProSelector {
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none;
    box-shadow: $default-shadow-s;
    border-radius: $border-radius-s;

    &--input {
        display: flex;
        flex-direction: row;
        justify-content: center;

        svg {
            margin-left: $default-space;
        }
    }

    &--size-l {
        select {
            height: $default-space-l;
        }
    }

    &--size-m {
        select {
            height: $default-space-m;
        }
    }

    &--portal {
        transform: translate(-43px, 16px);
        .bp3-menu {
            color: white;
            outline: none;
            background: $primary;
        }
    }

    &--value {
        color: $primary;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: $default-space $default-space-s;
        font-size: 14px;

        svg {
            margin-right: $default-space;
        }
    }

    &__select {
        @extend .ProButton;
        color: $white;
        height: 40px;
        display: flex;
        align-items: center;
        margin-left: 0;

        .ProAttrField__icon svg {
            margin-right: 0;
        }

        svg {
            fill: $white;
            margin-right: $default-space;
            margin-bottom: 0;
        }
    }
    &__singleValue {
        color: $brand-primary;
    }

    .bp3-menu {
        background-color: $primary;
    }
}
