.ProUserImage {
    display: flex;
    padding: $default-space-s;

    &__image {
        height: 150px;
        width: 150px;
        box-shadow: $default-shadow;
        border-radius: $default-space-m;
        margin-right: $default-space;
        background-color: #dddee3;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        img {
            position: absolute;
            left: 0;
            top: 0;
            object-fit: fill;
            height: 100%;
            aspect-ratio: 1/1;
        }

        input {
            opacity: 0;
            position: absolute;
            height: 100%;
            width: 100%;
        }
    }

    &__placement {
        opacity: 0.3;
    }

    &__buttons {
        margin-top: $default-space;
        display: flex;
        flex-direction: column;

        .ProButton {
            margin-bottom: $default-space;
        }
    }
}
