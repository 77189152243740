.ProAreaWrapper {
    margin: auto;
    // width: 100%;
    // min-width: 1400px;

    .imprint {
        display: block;
        text-align: center;
    }
}

.HomePage {
    border-radius: $default-space-m;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: $default-space-s;
    margin-top: $default-space;
    background-color: $white;
    position: relative;

    .HomePageLogo {
        position: absolute;
        left: $default-space-xl;
        top: $default-space-l;
    }

    &--App {
        background-color: $brand-primary;
        grid-template-rows: 0 1fr;
        padding: 0;
    }
}

.ProArea {
    $this: &;
    display: flex;
    flex-direction: row;
    overflow: auto;

    &__application {
        justify-content: center;
    }

    &__details {
        margin-left: $default-space-xl;

        #{$this}--section {
            max-width: 800px;
        }
    }

    a,
    &:hover {
        color: $brand-primary;
        text-decoration: none;
    }

    &__disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
    }

    &--spacer {
        margin: 0;
        // margin-bottom: $default-space-m;
        height: 74px;
        opacity: 0;
    }

    &__infoIcon {
        margin-left: $default-space-m;
        margin-left: auto;
        opacity: 0;
        transition: all 500ms ease-in-out;
    }

    &--section {
        margin: 0 $default-space-xl $default-space-l 0;
        max-width: 312px;
        word-break: break-word;

        &-head {
            display: flex;
            flex-direction: column;
            min-width: 300px;
            margin-bottom: 64px;
            margin-top: $default-space-l;

            h1 {
                margin: 0;
                // &:hover {
                //     text-decoration: underline;
                // }
            }

            svg {
                margin: 0 $default-space-m $default-space-m 0;
            }

            &__details {
                width: 800px;
                flex-direction: row;
                align-items: center;

                svg {
                    margin-bottom: 0;
                }
            }
        }

        ul {
            padding: 0;

            li {
                transition: all 500ms ease-in-out;
                list-style: none;
                font-size: 18px;

                padding: $default-space-m;
                border-radius: $border-radius;
                box-sizing: content-box;
                border: 1px solid rgba($color: $light-blue, $alpha: 0);

                display: flex;
                align-items: center;

                &:hover {
                    // border: 1px solid rgba($color: $light-blue, $alpha: 0.4);
                    background: rgba($color: $light-blue, $alpha: 0.2);

                    #{$this}__infoIcon {
                        opacity: 1;
                    }
                }

                div {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: $default-space-s;

                    transition: all 300ms ease-in-out;

                    &:hover {
                        text-decoration: none;
                    }
                }

                p {
                    max-width: 800px;
                    margin-bottom: 0;
                }

                svg {
                    margin-right: $default-space-s;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.ProNotification--portfolio {
    margin-top: $default-space-xl;
    // text-align: center;

    h3 {
        display: inline;
        margin-left: $default-space-s;
    }

    p {
        margin-top: $default-space-m;
        display: block;
    }
}

.ProSubMenu {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .spacer {
        min-width: 30px;
        flex-basis: 100%;
    }
}
