//Font

$font-family-sans-serif: 'Comfortaa', 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size: 14px;

//Colors:
$white: #f9f9fa;
$white-light: rgba(249, 249, 250, 0.3);
$black: #000;
// $blue: #1a2232;
$blue: #11151c;
$blue-light: rgba(64, 73, 92, 0.7);
$blue-lighter: rgba(95, 132, 180, 0.6);
$blue-lightest: rgba(152, 171, 212, 0.2);
$light-blue: #6e7fa3;

$secondary-lightest: rgba(109, 127, 163, 0.7);

$green: #2da44d;
$red: #c9384a;
$yellow: #f9ab45;
$pink: #b236eb;

$edit: $yellow;

$gray-lighter: rgba(
    $color: #000000,
    $alpha: 0.15,
);
$gray-lightest: rgba(
    $color: #000000,
    $alpha: 0.05,
);
$gray-light: rgba(
    $color: #000000,
    $alpha: 0.3,
);
$gray-darker: rgba(
    $color: #000000,
    $alpha: 0.6,
);

$gray-white: rgba(
    $color: #fff,
    $alpha: 0.5,
);
$gray: #676b73;
$gray-dark: #393b3f;

$brand-primary: $blue;
$brand-hightlight: $yellow;
$primary: $blue;
$primary-light: $blue-light;
$secondary: $light-blue;
$error: $red;
$succsess: $green;
$warning: $yellow;
$transparend: rgba(255, 255, 255, 0);

$pm-colors: (
    'black': $black,
    'white': $white,
    'primary': $primary,
    'primary-light': $primary-light,
    'primary-hightlight': $brand-hightlight,
    'secondary': $secondary,
    'secondary-light': $secondary-lightest,
    'error': $error,
    'succsess': $succsess,
    'warning': $warning,
    'gray': $gray,
    'gray-darker': $gray-dark,
    'gray-light': $gray-light,
    'blue-lighter': $blue-lightest,
    'create': $green,
    // 'create': $green-pastel,
    'transparend': $transparend,
    'edit': $edit,
);

//Shadows

//Sizes
$default-space: 8px;
$default-space-xs: calc($default-space / 2); //4
$default-space-s: calc(#{$default-space * 2}); //16
$default-space-m: calc(#{$default-space * 3}); //24
$default-space-l: calc(#{$default-space * 4}); //32
$default-space-xl: calc(#{$default-space * 5}); //40
$default-space-xxl: calc(#{$default-space * 12}); //96
$default-space-3xl: calc(#{$default-space * 18}); //144

$pm-sizes: (
    'xs': 14px,
    's': $default-space-s,
    'm': $default-space-m,
    'l': $default-space-l,
    'xl': $default-space-xl,
);

$border-radius-s: $default-space-xs;
$border-radius: $default-space;
$border-radius-l: 12px;

$default-shadow-selected: 0 0 16px 0 rgba(64, 73, 92, 0.5);
$default-shadow: 0 0 16px 0 rgba(64, 73, 92, 0.15);
$default-shadow-s: 0 0 8px 0 rgba(27, 24, 50, 0.16);

$font-size-s: 12px;
$font-size: 14px;
$font-size-m: 18px;
$font-size-l: 22px;
$font-size-xl: 32px;

$logo-size: 64px;
$logo-size--big: 128px;

$text-color: $white-light;
$text-color-disabled: $blue;

$pro-app-max-with: 2560px;
$pro-app-min-with: 1024px;
$pro-detail-window-max-with: 950px;
$pro-detail-window-min-with: 650px;
$tableOverflowBreakponint: 1730px;

$pro-dialog-with: 780px;

// Blueprint overright
$body-bg: #f8f8f8;
