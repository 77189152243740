.ProErrorPage {
    $this: &;
    width: 50vw;
    min-width: 800px;
    margin: 0 auto;

    &--head {
        display: flex;
        font-size: 120px;
        line-height: 110px;
        font-weight: 700;
        justify-content: space-between;
    }

    &--sub {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: $default-space-xl;
        width: 50%;
    }

    &--text {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: $default-space-xxl;
    }

    &__NotImplemented {
        #{$this}--head {
            margin-bottom: $default-space-xxl;
            span {
                margin-right: 30%;
            }
        }
        #{$this}--sub {
            width: 80%;
        }
    }

    .bp3-spinner {
        margin-bottom: $default-space-m;
    }
}
