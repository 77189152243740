.ProHeader {
    // grid-area: header;
    // position: absolute;
    // top: $default-space-m;
    // left: $default-space-3xl;

    &--headline {
        .ProIcon {
            margin-bottom: 0;
        }

        display: flex;
        flex-direction: row;
        align-items: center;

        margin-left: $default-space-m;
        margin-bottom: $default-space-m;
        padding-top: $default-space;

        &--icon {
            margin-right: $default-space-m;
        }

        h1 {
            margin-bottom: 0;
        }

        span {
            height: 24px;
            // width: 1px;
            display: block;
        }
    }
}
