.ProRow {
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    width: 100%;

    &__space {
        margin-bottom: $default-space-m;
    }
}

$pro-grid: (
    '1': 8.3%,
    '2': 16.6%,
    '3': 25%,
    '4': 33.3%,
    '5': 41.7%,
    '6': 50%,
    '7': 58.3%,
    '8': 66.7%,
    '9': 75%,
    '10': 83.3%,
    '11': 91.7%,
    '12': 100%,
);

.ProCol {
    display: flex;
    flex-direction: column;
    padding-right: $default-space-s;
    overflow: hidden;

    &--notHidden {
        overflow: visible;
    }
    &--block {
        display: block;
    }
    &--top {
        justify-content: start;
    }
    &--center {
        justify-content: center;
    }

    &--bottom {
        justify-content: end;
    }

    &--spaceAround {
        justify-content: space-around;
    }

    &:last-of-type {
        padding-right: 0;
    }

    &--direction-row {
        flex-direction: row;
    }
    &--direction-col {
        flex-direction: column;
    }

    // &--empty {
    //     margin-right: 0;
    // }

    @each $width, $percent in $pro-grid {
        &--span-#{$width} {
            width: $percent;
        }
    }
}
