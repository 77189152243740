.LoginWindow {
    display: flex;
    align-items: center;
    // justify-content: space-around;
    height: 90vh;
    flex-direction: column;
    transition: all 300ms ease-in-out;

    .login-window {
        margin-top: auto;
    }

    input {
        font-size: 18px;
        padding: 16px;
        height: 52px;
        border-radius: $default-space;
    }

    &__warning {
        color: $red;
    }

    &__success {
        color: $green;
    }

    textarea {
        border-radius: $default-space-s;
        padding: 16px;
    }

    &--loginButton {
        font-size: 20px !important;
        font-weight: 700;
        padding: 16px 20px !important;
        border-radius: $default-space !important;
    }

    &__content {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    &__logo {
        position: relative;
        top: 0;
        left: 0;
        margin-bottom: $default-space-xxl;
        margin-top: 10vh;
    }

    &__window {
        // width: 600px;
        min-height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: $default-shadow;
        background-color: $white;
        padding: $default-space-xxl;
        border-radius: $default-space-s;
        position: relative;
        transition: all 500ms ease-in-out;
        transition: all 300ms ease-in-out;
        // background-color: $blue-lightest;

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 520px;
        }
    }

    &__inviteButton {
        position: absolute;
        right: $default-space-l;
        bottom: $default-space-l;

        &:hover {
            text-decoration: underline;
        }
    }

    &__backButton {
        position: absolute;
        left: $default-space-l;
        top: $default-space-l;

        &:hover {
            text-decoration: underline;
        }
    }
}
