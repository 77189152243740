.ProDialog {
    $this: &;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $default-shadow;
    // bottom: $default-space-m;
    margin: 0;
    width: $pro-dialog-with;

    transition: all 0.5s ease-in-out;

    &--breadCrump {
        color: $white;
        margin: $default-space-s 0 $default-space-s $default-space;
    }

    &--header {
        background-color: $blue;
        border-radius: $border-radius $border-radius 0 0;
        display: flex;
        flex-direction: row;
        padding: $default-space-s $default-space-m $default-space-s $default-space-s;
        align-items: flex-start;
        justify-content: space-between;

        height: 110px;

        @each $color, $color-val in $pm-colors {
            &--intent-#{$color} {
                background-color: $color-val;
            }
        }

        &__title {
            display: flex;
            flex-direction: row;
            margin-bottom: $default-space;
            align-items: center;

            & > .ProIcon {
                margin-right: $default-space-s;
            }

            // .ProAttrField {
            //     margin: 0;
            // }
        }

        &__box {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
        }

        #{$this}--status {
            background-color: $gray-white;
            margin-left: auto;
            padding: 4px $default-space;
            border-radius: $border-radius-s;
        }

        h1,
        h2 {
            color: $white;
            margin: 0;
        }
    }

    &--buttons {
        display: flex;
        flex-direction: row;
        flex-grow: 2;
        justify-content: flex-end;
    }
    &--content {
        padding: $default-space-s $default-space-m;
        max-height: 70vh;
        overflow: auto;
    }

    @each $color, $color-val in $pm-colors {
        &--intent-#{$color} {
            background-color: $color-val;
        }
    }
}
