.ProLogo {
    position: absolute;
    top: $default-space;
    // left: $default-space;

    img {
        height: $logo-size;
        width: $logo-size;
    }

    &__big {
        img {
            height: $logo-size--big;
            width: $logo-size--big;
        }
    }
}
