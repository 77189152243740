.bp3-portal {
    z-index: 1001;
}

// .bp3-popover-content {
//     padding: 0 !important;
// }

.ProSelector--portal .bp3-menu {
    background: $white;
    color: $brand-primary;
}

.bp3-breadcrumb,
.bp3-breadcrumbs-collapsed {
    color: rgba(0, 0, 0, 0.3);
} // only for overview page

.ProDetailWindow,
.ProDialog--breadCrump {
    .bp3-breadcrumb,
    .bp3-breadcrumbs-collapsed {
        color: rgba(255, 255, 255, 0.6);
    }
}

.ProDetailWindow,
.ProDialog--breadCrump {
    .bp3-breadcrumb-current {
        color: white;
    }
}

.bp3-popover2-content {
    display: flex;
    flex-direction: column;
}

.bp3-breadcrumbs {
    white-space: nowrap;
}

.bp3-input::placeholder {
    color: $blue-light;
    opacity: 1; /* Firefox */
}
