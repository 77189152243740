.ProDetailWindow {
    $this: &;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $default-shadow-selected;
    bottom: $default-space-m;
    margin-bottom: $default-space-l;
    top: $default-space-s;
    right: $default-space-s;
    left: $default-space-s;
    position: absolute;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    transform: translate(100vw, 0);

    transition: all 0.5s ease-in-out;

    &--breadCrump {
        color: $white;
        margin: 0 0 $default-space-s 0;
        white-space: nowrap;
        overflow: hidden;
        justify-content: flex-start;
        width: 100%;
    }

    &__display {
        transform: translate(0, 0);
    }

    &--box {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
    }

    &--header {
        background-color: $blue;
        border-radius: $border-radius $border-radius 0 0;
        display: flex;
        flex-direction: column;
        padding: $default-space-s $default-space-m;
        align-items: flex-start;
        height: 147px;
        position: relative;
        // margin-bottom: $default-space;

        &--tabs {
            align-self: center;
            margin-top: 4px;
            margin-bottom: -36px;
            height: 36px;
            position: absolute;
            bottom: 18px;

            ul {
                display: flex;
                padding: 0;
                margin: 0;
                list-style: none;
                background-color: $white;
                border-radius: $default-space;

                li {
                    color: $brand-primary;
                    cursor: pointer;
                    font-size: 14px;
                    padding: 4px 16px 4px 16px;
                    border-radius: $default-space;
                    font-weight: 300;
                    border: 3px solid transparent;

                    transition: all 0.2s ease-in-out;

                    &.active {
                        background-color: $light-blue;
                        color: $white;
                        font-weight: 600;
                        padding: 8px 16px 8px 16px;
                        margin-top: -5px;
                        border: 3px solid $white;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &--title {
            display: flex;
            flex-direction: row;
            margin-bottom: $default-space;
            align-items: center;
            width: 100%;

            h1,
            h2 {
                color: $white;
                margin: 0;
                line-height: 32px;
            }

            &__large {
                h2 {
                    font-size: 20px;
                    line-height: 26px;
                }
            }

            & > .ProIcon {
                margin-right: $default-space-s;
                flex-shrink: 0;
            }

            .ProAttrField {
                margin: 0;
                width: 100%;
            }
        }

        #{$this}--status {
            background-color: $gray-white;
            margin-left: auto;
            padding: 4px $default-space;
            border-radius: $border-radius-s;
        }

        #{$this}--buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 0;
            margin-bottom: $default-space-s;
            flex-wrap: nowrap;
        }
    }

    &--content {
        padding: $default-space-m;
        overflow: auto;
        overflow-x: hidden;
        flex-grow: 1;

        &__withTabs {
            margin-top: $default-space-s;
        }
    }

    @each $color, $color-val in $pm-colors {
        &--intent-#{$color} {
            background-color: $color-val;
        }
    }

    .bp3-breadcrumb:hover {
        color: $white;
    }
}

.bp3-breadcrumbs-collapsed::before {
    content: '...';
    color: $white;
    font-size: 28px;
    line-height: 4px;
}

.bp3-breadcrumbs-collapsed,
.bp3-breadcrumbs-collapsed:hover {
    background: transparent;
}

.bp3-breadcrumbs {
    height: fit-content;
}
