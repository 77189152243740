// Custom styles
@import './variables';

// Import some 3rd party stylesheets
@import '~normalize.css';
@import '~bootstrap/scss/bootstrap.scss';
// @import '~@blueprintjs/core/src/blueprint.scss';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';
@import '~@blueprintjs/select/lib/css/blueprint-select.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/table/lib/css/table.css';
@import '~@blueprintjs/popover2/lib/css/blueprint-popover2.css';

@import './typo';

// Atom styles
@import './../atoms/Button/Button';
@import './../atoms/Input/Input';
@import './../atoms/Icons/Icon';
@import './../atoms/ProSelector/ProSelector';
@import './../atoms/MenuItems/MenuIcon';
@import './../atoms/Logo/ProLogo';
@import './../atoms/Table/Table';
@import './../atoms/Value/ProValue';
@import './../atoms/AttrField/AttrField';
@import './../atoms/Grid/Grid';
@import './../atoms/ProjectStatus/ProjectStatus';
@import './../atoms/ProDialog/ProDialog';
@import './../atoms/MenuCard/MenuCard';
@import './../atoms/SummaryWindow/SummaryWindow';
@import './../atoms/ProSpinner/Sinner';
@import './../atoms/CriteriaList/CriteriaList';

// Component styles
@import './../components/Header/Header';
@import './../components/Page/Page';
@import './../components/PageSettings/PageSettings';
@import './../components/DetailWindow/DetailWindow';
@import './../components/UserImage/UserImage';
@import './../components/FileList/FileList';

// Module styles
@import './../modules/Menu/Menu';
@import './../modules/LoginWindow/LoginWindow';
@import './../modules/AreaStartPage/AreaStartPage';
@import './../modules/Windows/SettingsDetails/ProjectTreshold';
@import './../modules/ProErrorPage/ProErrorPage';

// Route styles
@import './../routes/App/app';
@import './../routes/PortfolioManagement/Projects/Projects';
@import './../routes/ProjectManagement/Time/Time';
@import './../routes/TeamManagement/Ceremonies/Ceremonies.scss';

//Overwrites
@import './overwrites';
