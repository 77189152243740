.ProIcon {
    @each $color, $color-val in $pm-colors {
        &--fill-#{$color} {
            fill: $color-val;
        }

        &--hover-#{$color}:hover {
            fill: $color-val;
        }
    }
}
