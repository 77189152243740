$heading-styles: (
    'display-1': (
        'font-size': 32px,
        'font-weight': 400,
        'line-height': 4,
        'margin-top': 2,
        'margin-bottom': 2,
    ),
    'h1': (
        'font-size': 32px,
        'font-weight': 400,
        'line-height': 4,
        'margin-bottom': 4,
        'margin-top': 1,
    ),
    'h2': (
        'font-size': 24px,
        'font-weight': 400,
        'line-height': 3,
        'margin-bottom': 3,
    ),
    'h3': (
        'font-size': 16px,
        'font-weight': 700,
        'line-height': 3,
        'margin-bottom': 3,
    ),
    'h4': (
        'font-size': 16px,
        'font-weight': 400,
        'line-height': 3,
        'margin-bottom': 2,
    ),
    'p': (
        'font-size': 14px,
        'font-weight': 400,
        'line-height': 2,
        'margin-bottom': 3,
    ),
    'small': (
        'font-size': 12px,
        'font-weight': 400,
        'line-height': 2,
        'margin-bottom': 1,
    ),
);

@each $size, $val in $heading-styles {
    #{$size},
    .#{$size} {
        @if map-has-key($val, font-size) {
            font-size: map-get($val, font-size);
        }
        @if map-has-key($val, font-weight) {
            font-weight: map-get($val, font-weight);
        }
        @if map-has-key($val, line-height) {
            $line-height-mult: map-get($val, line-height);
            line-height: calc(#{$default-space} * #{$line-height-mult});
        }
        @if map-has-key($val, margin-bottom) {
            $margin-bottom-mult: map-get($val, margin-bottom);
            margin-bottom: calc(#{$default-space} * #{$margin-bottom-mult});
        } @else {
            margin-bottom: 0;
        }

        @if map-has-key($val, margin-top) {
            $margin-top-mult: map-get($val, margin-top);
            margin-top: calc(#{$default-space} * #{$margin-top-mult});
        } @else {
            margin-top: 0;
        }

        @if map-has-key($val, margin-top) {
            $margin-top-mult: map-get($val, margin-top);
            margin-top: calc(#{$default-space} * #{$margin-top-mult});
        } @else {
            margin-top: 0;
        }

        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;

        span {
            margin-right: default-space-s;
        }
    }
}

@at-root {
    @font-face {
        font-family: 'Ubuntu Mono';
        font-weight: normal;
        font-style: normal;
        src: url('../statics/ubuntu-mono-400.eot'); /* IE9 Compat Modes */
        src: local(''), url('../statics/ubuntu-mono-400.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */ url('../statics/ubuntu-mono-400.woff2') format('woff2'),
            /* Super Modern Browsers */ url('../statics/ubuntu-mono-400.woff') format('woff'),
            /* Modern Browsers */ url('../statics/ubuntu-mono-400.ttf') format('truetype'),
            /* Safari, Android, iOS */ url('../statics/ubuntu-mono-400.svg#UbuntuMono') format('svg'); /* Legacy iOS */
    }
    @font-face {
        font-family: 'Comfortaa';
        font-weight: normal;
        font-style: normal;
        src: url('../statics/comfortaa-400.eot'); /* IE9 Compat Modes */
        src: local(''), url('../statics/comfortaa-400.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */ url('../statics/comfortaa-400.woff2') format('woff2'),
            /* Super Modern Browsers */ url('../statics/comfortaa-400.woff') format('woff'),
            /* Modern Browsers */ url('../statics/comfortaa-400.ttf') format('truetype'),
            /* Safari, Android, iOS */ url('../statics/comfortaa-400.svg#Comfortaa') format('svg'); /* Legacy iOS */
    }

    @font-face {
        font-family: 'Comfortaa';
        font-weight: 700;
        font-style: normal;
        src: url('../statics/comfortaa-700.eot'); /* IE9 Compat Modes */
        src: local(''), url('../statics/comfortaa-700.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */ url('../statics/comfortaa-700.woff2') format('woff2'),
            /* Super Modern Browsers */ url('../statics/comfortaa-700.woff') format('woff'),
            /* Modern Browsers */ url('../statics/comfortaa-700.ttf') format('truetype'),
            /* Safari, Android, iOS */ url('../statics/comfortaa-700.svg#Comfortaa') format('svg'); /* Legacy iOS */
    }
}

body {
    font-family: 'Comfortaa', 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
