.ProApp {
    $this: &;
    background-color: $brand-primary;
    position: relative;
    height: 100vh;

    display: grid;
    // grid-gap: $default-space;

    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'menu mainframe'
        'menu mainframe';

    .ProApp--content {
        max-width: none !important;
    }

    &__loading {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        div {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                margin-top: $default-space-m;
                font-size: 16px;
            }
        }
    }

    &__mainframe {
        grid-area: mainframe;

        display: grid;
        grid-gap: $default-space;

        grid-template-columns: auto;
        grid-template-rows: 70px 1fr;
        grid-template-areas:
            'header'
            'content';

        background-color: $body-bg;

        // border-radius: $border-radius 0 0 $border-radius;
        border-radius: $default-space-m;
        margin: $default-space $default-space $default-space 0;

        padding: $default-space-m 0 0 $default-space-m;

        #{$this}--content {
            display: flex;
            grid-area: content;
            position: relative;
            overflow-x: hidden;
            position: relative;

            max-width: $pro-app-max-with;
            min-width: $pro-app-min-with;
        }
    }

    &__hidden {
        // display: none !important;
        display: none;
    }

    &__logo {
        position: absolute;
        top: $default-space-s;
        left: $default-space-s;
    }

    &--detailWindowSection {
        position: relative;
        overflow: hidden;
        height: 100%;

        min-width: 0;
        max-width: calc(#{$pro-detail-window-max-with} + #{$default-space-l});

        &__expanded {
            min-width: calc(#{$pro-detail-window-min-with} + #{$default-space-l});
            max-width: calc(#{$pro-detail-window-max-with} + #{$default-space-l});
            flex: 1 1 $pro-app-max-with;

            @media screen and (max-width: $tableOverflowBreakponint) {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    // &__errorOrLoading {
    //     height: 100%;
    //     width: 100%;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;

    //     .bp3-spinner {
    //         margin-bottom: $default-space-m;
    //     }

    //     p {
    //         width: fit-content;
    //     }
    // }
}

.ProMessage {
    margin-top: $default-space-m;
    &--error {
        color: $red;
    }
    &--success {
        color: $green;
    }
}
