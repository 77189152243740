.ProMenu {
    $this: &;
    grid-area: menu;

    display: flex;
    flex-direction: column;
    height: 100vh;
    // min-width: 120px;

    background-color: $brand-primary;

    &--hidden {
        width: 0;
        min-width: 0;
    }

    &__lists {
        margin: $default-space-3xl $default-space-s;
        padding: 0;
        height: 100%;
        position: relative;
    }

    &__area-list {
        margin-top: 0;
        margin-left: calc(#{$default-space-l} * (-1));
        padding: $default-space $default-space-s $default-space $default-space-m;
        min-width: calc(#{$default-space} * 16);
        list-style: none;
        background: $brand-hightlight;
        // border-radius: $border-radius-l;
        border-radius: $default-space;
        box-shadow: $default-shadow-selected;

        position: absolute;
        z-index: 99;

        transition: all 500ms ease-in-out;
        transition-delay: 1000ms;

        display: flex;
        flex-direction: row;
        justify-content: center;

        li {
            padding: 0;
            transition: all 500ms ease-in-out;
        }

        li .ProMenuIcon {
            max-width: 0;
            opacity: 0;
            overflow: hidden;
            transition: all 500ms ease-in-out;
        }
        li .ProMenuIcon.ProMenuIcon--active {
            max-width: 300px;
            opacity: 1;
        }

        .ProMenuIcon {
            margin-bottom: 0;
        }

        &:hover {
            left: 0;
            // padding: $default-space $default-space-s $default-space $default-space-l;

            li {
                margin-left: $default-space;
                .ProMenuIcon {
                    max-width: 300px;
                    opacity: 1;
                    // margin-right: $default-space-s;
                }
                &:first-of-type {
                    margin-left: $default-space-s;
                }
            }
        }
    }

    &__item-list {
        margin: $default-space-xxl auto 0 auto;
        padding: 0;
        // margin-top: $default-space-xxl;
        list-style: none;
        // max-width: 600px;

        &--spacer {
            height: $default-space-l;
            opacity: 0;
        }

        transition: all 500ms ease-in-out;

        li {
            // padding: 4px $default-space;
            margin-bottom: $default-space;
            // border-radius: $border-radius-l;

            transition: all 500ms ease-in-out;

            a {
                text-decoration: none;
            }

            .ProMenuIcon {
                width: 64px;
                transition: all 500ms ease-in-out;
                border: 1px solid transparent;
                border-radius: $default-space;
                &:hover {
                    // background-color: rgba(255, 255, 255, 0.3);
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    transition: all 500ms ease-in-out;
                }
                svg {
                    height: 32px;
                }
            }
        }

        li:hover.active .ProMenuIcon {
            background-color: $white;
            transition: all 500ms ease-in-out;
        }

        li a {
            display: flex;
            color: $white;
            align-items: center;
            display: flex;
            flex-direction: row;
            align-items: center;

            h3 {
                text-decoration: none;
                margin: 0;
                font-weight: 400;
                padding-left: $default-space-s;
            }
        }

        .ProMenuIcon {
            margin: 0;
        }
    }

    &--extendButton {
        position: absolute;
        bottom: $default-space-m;
        // left: $default-space-l;
        left: 26px;
        z-index: 999;
        width: 40px;
    }

    &--open {
        #{$this}__lists {
            width: calc(#{$default-space} * 32);
        }

        #{$this}__area-list {
            li .ProMenuIcon {
                max-width: 300px;
            }
        }

        li .ProMenuIcon {
            margin-right: $default-space;
            margin-left: 0;
            opacity: 1;
            width: 64px;
        }

        li {
            border: 1px solid rgba(255, 255, 255, 0);
            // &:hover {
            //     // background-color: rgba(255, 255, 255, 0.3);
            //     border: 1px solid rgba(255, 255, 255, 0.3);
            //     transition: all 500ms ease-in-out;
            // }
        }

        li:hover .ProMenuIcon {
            background-color: transparent;
            transition: all 500ms ease-in-out;
        }

        li.active {
            background-color: $white;
            border-radius: $border-radius-l;

            a {
                color: $primary;
            }
        }
    }

    //Specifity overwite
    .#{$ns}-button:not([class*='bp3-intent-']) {
        @extend .ProMenu--extendButton;
    }
}
