.ProMenu--link {
    height: fit-content;
}

.ProMenuCardWrapper {
    margin-left: $default-space-s;
    margin-top: $default-space-s;
    margin-bottom: $default-space-s;

    &--vertical {
        width: 360px;
    }
}

.ProMenuCard {
    $this: &;
    padding: $default-space-s;

    border-radius: $border-radius;
    box-shadow: $default-shadow;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    overflow: hidden;
    z-index: 10;
    background-color: $white;
    position: relative;

    transition: all 800ms ease-in-out;

    min-height: 200px; //animation

    &--vertical {
        min-height: 180px;
    }

    &--vertical {
        width: 340px;
    }

    &--bar {
        width: $default-space;
        background-color: $blue-light;
        border-radius: $border-radius;
        color: transparent;

        transition: all 800ms ease-in-out;

        &--horisontal {
            height: $default-space;
            position: absolute;
            bottom: $default-space;
            left: $default-space;
            right: $default-space;
            width: auto;
        }
    }

    &__iconSection {
        // margin-top: $default-space-m;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;

        position: absolute;
        bottom: 32px;
        right: 8px;
        left: 8px;
    }

    &--content {
        padding: $default-space $default-space-m $default-space-s $default-space-s;
        width: 100%;
    }

    &--titleRow {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        // margin-bottom: 0; //animation
        margin-bottom: $default-space-s; //animation

        transition: all 800ms ease-in-out;
    }

    &--icon,
    &--icon:hover {
        margin-right: $default-space-m;
    }

    &--name,
    &--name:hover {
        @extend h2;
        text-decoration: none;
        color: $brand-primary;
        margin-bottom: 0;
        line-height: 24px;
        font-size: 22px;

        transition: all 800ms ease-in-out;
    }

    &--description {
        width: 100%;
        text-decoration: none;
        color: $brand-primary;
        max-height: 0;
        overflow: hidden;
        opacity: 0;

        max-height: 200px; //animation
        opacity: 1; //animation

        transition: all 800ms ease-in-out;
    }

    &:hover {
        box-shadow: $default-shadow-selected;
        z-index: 12;

        #{$this}--bar {
            background-color: $blue;
        }

        #{$this}--description {
            max-height: 200px;
            opacity: 1;
        }

        #{$this}--titleRow {
            margin-bottom: $default-space-s;
        }
    }
}

.NoHover:hover {
    text-decoration: none;
    color: $brand-primary;
}

.MenuCardContainer {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%;
    max-width: 1900px;
    align-content: flex-start;
}
