.CriteriaList {
    font-size: $font-size;
    width: 100%;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: $default-space-xl;

        li {
            margin-top: $default-space-s;
            background-color: $blue-lightest;
            border-radius: $border-radius;
            padding: 4px;
            display: flex;
            align-items: center;
            border: 1px solid transparent;

            &:hover {
                box-shadow: $default-shadow;
                border: 1px solid rgba(152, 171, 212, 0.1);
            }

            svg {
                margin: 4px;
            }
        }
    }

    &__disabled {
        ul {
            li {
                &:hover {
                    box-shadow: none;
                }
            }
        }
    }

    &--name {
        margin: 0 $default-space-s;
        display: flex;
        font-size: 16px;
    }

    &--task {
        margin: 0 $default-space-s;
        display: flex;
        width: 100%;

        &__empty {
            justify-content: center;
        }

        span {
            border: none;
            width: 100%;
        }
    }

    &--button {
        margin-left: auto !important;
    }

    &--deleteButton {
        margin-right: $default-space;
    }

    input,
    textarea {
        background: none;
        border: none;
        width: 100%;
        min-height: fit-content;
        // resize: none;
    }
}
