.ProInput {
    margin-bottom: $default-space-m;

    &--fullWidth {
        width: 100%;

        input {
            width: 100%;
        }
    }

    input {
        @extend p;
        border-radius: $border-radius;
        padding: $default-space-s $default-space;
        margin: 0;
    }

    &--large {
        input {
            @extend h4;
            padding: $default-space-m $default-space-s;
        }
    }

    &:last-of-type {
        margin: 0;
    }

    @each $color, $color-val in $pm-colors {
        &--intent-#{$color} {
            input,
            textarea {
                background-color: $color-val !important;
                color: $white !important;

                svg {
                    fill: $white;
                }
            }
        }
    }
}
