.ProStatus {
    display: flex;
    flex-direction: row;

    // border-radius: $default-space;
    overflow: hidden;
    line-height: initial;

    a {
        &:first-of-type div {
            border-radius: 4px 0 0 4px;
        }
        &:last-of-type div {
            border-radius: 0 4px 4px 0;
        }
    }

    &--icon {
        height: 28px;
        width: 32px;
        background-color: $gray-lighter;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1px;

        &__DivisionByZero {
            background-color: $gray-lighter;
        }

        &__Normal {
            background: #56b26f;
        }

        // Version one
        &__CautionMinimum {
            background: $yellow;
        }

        &__CriticalMinimum {
            background: $red;
        }

        &__CautionMaximum {
            background: $yellow;
        }

        &__CriticalMaximum {
            background: $red;
        }

        &__criticalMin {
            background: $pink;
        }

        &__criticalMax {
            background: $pink;
        }

        // // Version two
        // &__CautionMinimum {
        //     background: $yellow;
        //     background: linear-gradient(0deg, $yellow 0%, $yellow 80%, $green 100%);
        // }

        // &__CriticalMinimum {
        //     background: $red;
        //     background: linear-gradient(0deg, $red 0%, $red 60%, $yellow 100%);
        // }

        // &__CautionMaximum {
        //     background: $yellow;
        //     background: linear-gradient(0deg, $green 0%, $yellow 20%, $yellow 100%);
        // }

        // &__CriticalMaximum {
        //     background: $red;
        //     background: linear-gradient(0deg, $yellow 0%, $red 35%, $red 100%);
        // }

        // &__criticalMin {
        //     background: $pink;
        //     background: linear-gradient(0deg, $pink 0%, $pink 70%, $red 100%);
        // }

        // &__criticalMax {
        //     background: $pink;
        //     background: linear-gradient(0deg, $red 0%, $pink 30%, $pink 100%);
        // }

        // Version tree
        // &__CautionMinimum {
        //     background: linear-gradient(0deg, $yellow 0%, $yellow 80%, $green 80%, $green 100%);
        // }

        // &__CriticalMinimum {
        //     background: linear-gradient(0deg, $red 0%, $red 80%, $yellow 80%, $yellow 100%);
        // }

        // &__CautionMaximum {
        //     background: linear-gradient(0deg, $green 0%, $green 20%, $yellow 20%, $yellow 100%);
        // }

        // &__CriticalMaximum {
        //     background: linear-gradient(0deg, $yellow 0%, $yellow 20%, $red 20%, $red 100%);
        // }

        // &__criticalMin {
        //     background: linear-gradient(0deg, $pink 0%, $pink 80%, $red 80%, $red 100%);
        // }

        // &__criticalMax {
        //     background: linear-gradient(0deg, $red 0%, $red 20%, $pink 20%, $pink 100%);
        // }
    }
}
