.ProjectStatus {
    // svg {
    //     margin-right: $default-space;
    // }
}

.ProSearch {
    position: relative;
    &::before {
        content: 'Search';
        position: absolute;
        top: -48px;
        color: $white;
        font-size: $font-size-xl;
    }
}

.bp3-omnibar {
    border-radius: $border-radius !important;
    color: $white !important;
    background-color: #9db3d1;

    a {
        color: rgba(255, 255, 255, 0.8);
    }

    input {
        color: $white;
        font-weight: 700;
        border-radius: $border-radius !important;
    }
}
